import React, {useState} from 'react';
import {Helmet} from 'react-helmet';
import {privacyPolicyUrl, portalId, subscribeFormId} from '../api';
import {Button} from '../components/button/button';
import {Layout} from '../components/layout/layout';
import {mark} from '../utils/marked';
import './blog-post.scss';
import {ErrorTypes} from '../pages-components/join-us/form/form';

const Form = () => {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState({});
  const [email, setEmail] = useState('');

  const submitForm = e => {
    if (e) e.preventDefault();
    console.log('here');
    if (!email) {
      console.log(!email);
      return;
    }
    console.log('here');

    const isBrowser = typeof window !== 'undefined';
    const pageUri = isBrowser ? window.location.href : null;
    const pageName = isBrowser ? document.title : null;
    const postUrl = `https://api.hsforms.com/submissions/v3/integration/submit/${portalId}/${subscribeFormId}`;

    setError({});
    setSuccess(false);
    setLoading(true);
    const body = {
      submittedAt: Date.now(),
      fields: [
        {
          name: 'email',
          value: email,
        },
      ],
      context: {
        pageUri,
        pageName,
      },
    };

    const payload = JSON.stringify(body);
    const xhr = new XMLHttpRequest();

    xhr.open('POST', postUrl);
    xhr.setRequestHeader('Content-Type', 'application/json');
    xhr.onreadystatechange = function () {
      if (xhr.readyState == 4 && xhr.status == 200) {
        setSuccess(true);
        setEmail('');
        setLoading(false);
      } else if (xhr.readyState == 4 && [400, 403, 404].includes(xhr.status)) {
        if (JSON.parse(xhr.response)?.errors?.map(error => error.errorType === ErrorTypes.Email)) {
          setError({email: 'Invalid email address.'});
        } else {
          setError({form: 'We had some issues with submission. Try again later.'});
        }
        setSuccess(false);
        setLoading(false);
      }
    };

    // Set data for analitics.
    if (isBrowser) {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: `emailSignup`,
      });
    }

    // Sends the request
    xhr.send(payload);
  };

  return (
    <div className='form'>
      <div className='form_title'>
        <strong>Get More</strong> Stories Like This
      </div>
      <div className='form_wrapper'>
        <div className='form_content'>
          <p className='form_content_subtitle'>
            Don't miss out on any content that will help you to achieve desired outcomes and smoothly run IT projects.
          </p>
        </div>
        <div className='form_sign'>
          <div className='form_sign_wrapper'>
            <div className='form_sign_email'>
              <input
                className='form_sign_input'
                type='email'
                placeholder='Enter your email'
                value={email}
                onChange={e => setEmail(e.target.value)}
              />
            </div>
            <div className='form_sign_button'>
              <Button disabled={loading || success} onClick={submitForm}>
                {success ? 'Submitted' : loading ? 'Sending' : 'Sign Me Up'}
              </Button>
            </div>
          </div>
          {error.form && <span>{error.form}</span>}
          <div className='form_sign_policy'>
            Subscription implies consent to our{' '}
            <a className='link' href={privacyPolicyUrl} rel='noreferrer' target='_blank'>
              privacy policy.
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

// eslint-disable-next-line react/display-name
export default ({
  pageContext: {
    post: {Thumbnail_img, meta_description, Date, Categories, time_to_read, title_tag, Content},
  },
}) => {
  return (
    <>
      <Helmet
        meta={[
          {name: 'description', content: meta_description},
          {name: 'title', content: title_tag},
          {
            name: 'image',
            content: Thumbnail_img?.publicURL,
          },
          {
            property: 'og:type',
            content: 'article',
          },
          {
            property: 'og:title',
            content: title_tag,
          },
          {
            property: 'og:image',
            content: Thumbnail_img?.publicURL,
          },
        ]}
      >
        <title>{title_tag}</title>
      </Helmet>

      <Layout>
        <div className='section'>
          <div className={'time'}>
            {Date} - {time_to_read} MIN READ
          </div>
          <div className={'categories'}>
            {Categories.map((category, index) => (
              <div key={index} className={'tile'}>
                {category.name}
              </div>
            ))}
          </div>
          <div dangerouslySetInnerHTML={mark(Content)}></div>
          <div className={'divider'} />
          <Form />
        </div>
      </Layout>
    </>
  );
};
